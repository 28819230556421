import React, { useContext, useEffect, useState } from 'react'
import { HiMenuAlt2 } from "react-icons/hi";
import { FaBorderAll, FaCircleUser, FaUserLarge } from "react-icons/fa6";
import { FaCartShopping } from "react-icons/fa6";
import { IoClose, IoSearchSharp } from "react-icons/io5";
import logo from "./img/logo.png";
import { Link, useNavigate } from 'react-router-dom';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { userLogOut } from "../Redux/actions";
import Cart from '../commonComponent/cardDetails';
import Login from '../Register/Register';
import { IoIosLogIn } from 'react-icons/io';
import { postApiData } from '../utils/services';
import { CartContext } from '../context/CartContext';
const SmallNavbar = () => {
    const { isCartOpen,setIsCartOpen,cartTotal,setCartTotal,searchResults,setSearchResults} = useContext(CartContext);
    const cartLengthData=useSelector((store)=>store.addProduct.cart)
    const [hamburgermenu, sethamburgermenu] = useState(false);
    const [branddetails, setbrandDetails] = useState(false);
    const [showCart, setShowCart] = useState(false);
    const [test, setTest] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [loginModal, setLoginModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const authToken = useSelector((state) => state?.authReducer?.userData);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    useEffect(()=>{
        const data ={
          name : searchTerm
        }
        if (searchTerm.length > 0) {
          const data = {}
        postApiData(
          `user/getSearchedProduct?name=${searchTerm}`,
          data,
          (resp)=>{
            console.log("search ke products",resp)
            setSearchResults(resp)
          },
          (error)=>{
            console.log("search ki error",error)
          }
        )
        }else{
          setSearchResults([])
        }
      },[searchTerm])
    
    function hamburgermenuopen() {
        sethamburgermenu(!hamburgermenu);
      }

      function handleLinkClick() {
        sethamburgermenu(false);
      }

      function brandddetails() {
        setbrandDetails(!branddetails);
      }

      const smalllogout = () => {
        dispatch(userLogOut());
        sethamburgermenu(false);
      };

      function loginmodelpress() {
        setLoginModal(!loginModal);
        setIsOpen(!modalIsOpen);
      }

      const onpress = () => {
        // setIsOpen(false);
        // setShowCart(true);
        setIsCartOpen(true)
      };
      const  openSmallUserModal=()=>{
        console.log("before",modalIsOpen)
        if(!authToken){
          navigate('/login')
        }else{
          setIsOpen(!modalIsOpen)
        }
        console.log("after",modalIsOpen)
      }
      function hanldeMyProfileButtons(){
        setIsOpen(!modalIsOpen);
        navigate('/myprofile')
      }
      const loginpress = () => {
        navigate("/ordersummary")
      };
      const handleSearch = (event) => {
        const value = event.target.value
        if (value === '') {
          setSearchTerm('');
          navigate('/');
        } else {
          setSearchTerm(value);
          navigate(`/search${value ? `?${value}` : ''}`);
        }
      };
    
  return (
    <div className='w-full bg-black  flex flex-col px-3 py-4 top-0 sticky z-10'>
        <div className='flex justify-between items-center'>
        <HiMenuAlt2 className='text-3xl font-bold text-white' onClick={hamburgermenuopen}/>
        <Link to="/"><img loading='lazy' className="w-auto h-[25px]" src={logo} alt="logo" /></Link>
        <div className='flex justify-between items-center gap-x-3'>
        <FaUserLarge className='text-xl font-bold text-white' onClick={openSmallUserModal}/>
        <div className='relative'>
        <p className="text-xs text-gray-300 bg-red-500 rounded-full px-1 absolute -top-3 -right-2 ">{cartLengthData.length}</p>
        <FaCartShopping className='text-xl font-bold text-white' onClick={onpress}/>
        </div>
        </div>
        </div>
        <div className='flex relative'>
            <input type='search' className='w-full mt-2 mx-3 outline-none border-none px-3 py-1' placeholder='Search by product...' onChange={handleSearch}/>
            <IoSearchSharp className='text-black absolute right-4 top-3 text-2xl font-bold '/>
        </div>
        {hamburgermenu && (
              <div
                className={`fixed z-1000 h-[100%] bg-black w-[80%] left-0 top-0 overflow-y-auto duration-200 ease-linear sedan-sc-regular  ${
                  hamburgermenu ? "slide-in" : "slide-out"
                }`}
              >
                {/* <button className="text-white"> */}
                  <IoClose className='text-4xl text-white absolute right-2 my-5'  onClick={hamburgermenuopen}/>
                {/* </button> */}
                <div className="text-white text-lg mt-20 mb-10 mx-5">
                  <Link
                    to={{
                      pathname: "/best-sellers",
                    }}
                    className="text-[23px] flex justify-start item-center p-[12px] border-b border-white"
                    onClick={handleLinkClick}
                  >
                    Best Seller
                  </Link>
                  <div className="brands-container " onClick={brandddetails}>
                    <label className="text-[23px] flex justify-start item-center p-[12px] border-b border-white w-full">Brands</label>
                    <MdKeyboardArrowDown className="brandaicon" />
                  </div>
                  {branddetails && (
                    <div className="optionsbrand">
                      <h2>
                        <Link
                          to="/skinco"
                          className="brand-link"
                          onClick={handleLinkClick}
                        >
                          Skinco
                        </Link>
                      </h2>
                      <h2>
                        <Link
                          to="/argatin"
                          className="brand-link"
                          onClick={handleLinkClick}
                        >
                          Argatin
                        </Link>
                      </h2>
                      <h2>
                        <Link
                          to="/spring"
                          className="brand-link"
                          onClick={handleLinkClick}
                        >
                          Spring H2O
                        </Link>
                      </h2>
                      <h2><Link to="/loreal" className="brand-link"  onClick={handleLinkClick}>Loreal</Link></h2>
                    </div>
                  )}

                  <Link
                    to={{
                      pathname: "/hair-care",
                    }}
                    className="text-[23px] flex justify-start item-center p-[12px] border-b border-white"
                    onClick={handleLinkClick}
                  >
                    Haircare
                  </Link>

                  <Link
                    to={{
                      pathname: "/skin-care",
                    }}
                    className="text-[23px] flex justify-start item-center p-[12px] border-b border-white"
                    onClick={handleLinkClick}
                  >
                    Skincare
                  </Link>

                  <Link
                    to={{
                      pathname: "/distributer",
                    }}
                    className="text-[23px] flex justify-start item-center p-[12px] border-b border-white"
                    onClick={handleLinkClick}
                  >
                    Distribution
                  </Link>

                  <Link
                    to={{
                      pathname: "/ContactUs",
                    }}
                    className="text-[23px] flex justify-start item-center p-[12px] border-b border-white"
                    onClick={handleLinkClick}
                  >
                    Contact us
                  </Link>

                  <Link
                    to={{
                      pathname: "/product-combo",
                    }}
                    className="text-[23px] flex justify-start item-center p-[12px] border-b border-white"
                    onClick={handleLinkClick}
                  >
                    Product combo
                  </Link>
                  {
                    authToken && (

                  <button className="text-[23px] flex justify-start item-center p-[12px] border-b border-white w-full" onClick={smalllogout}>Logout</button>
                    )
                  }
                </div>
              </div>
            )} 

            {/* {showCart && (
                 <Cart setShowCart={setShowCart} setTest={setTest} test={test} />
               )} */}
               {isCartOpen&& (
                <Cart setShowCart={setIsCartOpen} isCartOpen={isCartOpen} setTest={setTest} test={test} />
              )}

               {/* {loginModal && (
                <Login modalIsOpen={loginModal} setIsOpen={setLoginModal} />
              )} */}

              {
                modalIsOpen && (
                  <div className="w-[200px] h-auto bg-white rounded-lg shadow-xl fixed z-10 top-40 right-20">
                  <div className="">
                    {!authToken && (
                      <button className="text-lg w-full px-3 py-2" onClick={loginmodelpress()}>
                      Login
                      </button>
                    )}
                  </div>

                <div className="" >
              {
                authToken && (
                  <div className="dropdown-submenu">
                    <FaCircleUser />
                    <div className="submenu-content" onClick={hanldeMyProfileButtons()}>
                      <h1>My Profile</h1>
                    </div>
                  </div>
                )
              }
                 
                  </div>

                  <div className="">
                    <div className="dropdown-submenu">
                      <FaBorderAll />
                      <div className="submenu-content"  onClick={loginpress} >
                        <h1>My Orders</h1>
                      </div>
                    </div>
                  </div>

                  {authToken && (
                    <div className="dropdown-submenu"  >
                      <IoIosLogIn />
                      <div className="submenu-content" onClick={smalllogout}>
                        <h1>Logout</h1>
                      </div>
                    </div>
                  )}
                </div>
             
                )
              }
    </div>
  )
}

export default SmallNavbar