import "./App.css";
import { lazy, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { CartProvider } from "./context/CartContext";
import ReactGA from "react-ga";
import { useEffect } from "react";
import Navbar from "./Navbar/Navbar";
import NewFooter from "./components/footer/newFooter";
import OverlayLoader from "./components/loader/OverlayLoader";
import Training from "./Training/Training";

const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem("retry-lazy-refreshed") || "false"
    );

    componentImport()
      .then((component) => {
        window.sessionStorage.setItem("retry-lazy-refreshed", "false");
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem("retry-lazy-refreshed", "true");
          return window.location.reload(); // refresh the page
        }
        reject(error);
      });
  });
};
const Home = lazy(() => lazyRetry(() => import("./Auth/auth")));
// import store from './Redux/store/store';
const Skinco = lazy(() => lazyRetry(() => import("./components/skinco")));
const Cartdescription = lazy(() =>
  lazyRetry(() => import("./Cartdescription/Cartdescription"))
);
const Haircareallproducts = lazy(() =>
  lazyRetry(() => import("./Haircareallproducts/Haircareallproducts"))
);
const Skincareallproducts = lazy(() =>
  lazyRetry(() => import("./Skincareallproducts/Skincareallproducts"))
);
const Argatin = lazy(() => lazyRetry(() => import("./components/argatin")));
const SignUp = lazy(() => lazyRetry(() => import("./Login/Login")));
const ResetPassword = lazy(() =>
  lazyRetry(() => import("./components/resetpassword"))
);
const Menicure = lazy(() => lazyRetry(() => import("./components/menicure")));
const FacialKit = lazy(() => lazyRetry(() => import("./components/facialkit")));
const Profile = lazy(() => lazyRetry(() => import("./components/profile")));
const Faq = lazy(() => lazyRetry(() => import("./components/faq")));
const PrivacyPolicyComponent = lazy(() =>
  lazyRetry(() => import("./components/privacyPolicy"))
);
const TermsAndCondition = lazy(() =>
  lazyRetry(() => import("./components/termsAndcondition"))
);
const ReturnRefund = lazy(() =>
  lazyRetry(() => import("./components/refundAndReturn"))
);
const AboutUs = lazy(() => lazyRetry(() => import("./components/aboutus")));
const Distributer = lazy(() =>
  lazyRetry(() => import("./components/distributerpage"))
);

//import LoginForm from "./Loginform/Loginform";
const Productformdetails = lazy(() =>
  lazyRetry(() => import("./Productformdetails/Productformdetails"))
);
const Editform = lazy(() => lazyRetry(() => import("./Editform/Editform")));
const Argatinshampoo = lazy(() =>
  lazyRetry(() => import("./Argatinshampoo/Argatinshampoo"))
);
const Hairconditioner = lazy(() =>
  lazyRetry(() => import("./Hairconditioner/Hairconditioner"))
);
const Hairoil = lazy(() => lazyRetry(() => import("./Hairoil/Hairoil")));
const Argatinkeratintreatment = lazy(() =>
  lazyRetry(() => import("./Argatinkeratintreatment/Argatinkeratintreatment"))
);
const Listcombo = lazy(() => lazyRetry(() => import("./productcombo")));
const Productupdateform = lazy(() =>
  lazyRetry(() => import("./productupdateform/Productupdateform"))
);
const Formupdate = lazy(() =>
  lazyRetry(() => import("./formupdate/formUpdate"))
);
const ContactUs = lazy(() => lazyRetry(() => import("./components/contactus")));

const Bestsellerlist = lazy(() =>
  lazyRetry(() => import("./Bestsellerlist/Bestsellerlist"))
);
const Loreal = lazy(() => lazyRetry(() => import("./components/lorealbrand")));
const Lorealallproducts = lazy(() =>
  lazyRetry(() => import("./lorealAllproducts"))
);
const Btxhaircare = lazy(() =>
  lazyRetry(() => import("./Btxhaircare/Btxhaircare"))
);
const Spring = lazy(() => lazyRetry(() => import("./components/springh2o")));

const YourOrders = lazy(() => lazyRetry(() => import("./Ordersummary")));
const SearchResult = lazy(() =>
  lazyRetry(() => import("./components/searchResult"))
);
const Address = lazy(() => lazyRetry(() => import("./components/address")));
const SuccessOrder = lazy(() => lazyRetry(() => import("./SuccessOrder")));
const Unlisting = lazy(() =>
  lazyRetry(() => import("./components/unlisiting"))
);
const Shipping = lazy(() =>
  lazyRetry(() => import("./commonComponent/addressModal/Shipping"))
);
const Logins = lazy(() => lazyRetry(() => import("./Login")));
const ProductDiscription = lazy(() =>
  lazyRetry(() => import("./Cartdescription"))
);
const Sitemap = lazy(() => lazyRetry(() => import("./sitemap")));

const TRACKING_ID = "G-LHBQBP8CYF"; // Replace with your tracking ID

ReactGA.initialize(TRACKING_ID);

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
async function requestPermission() {
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    //  const token = await getToken(messaging,{vapidKey:"BFfxF_oF6r4qTi9yXOyFNnuniaU3amqoLCFkXeBWgAWKhrs28zv1AMyVS92HA91bQvDTMfHwh0lGYAA8ZVKGit8"});
    //  console.log(token,"token")
  } else if (permission === "denied") {
    // alert("Notification Permission Denied");
  }
}

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    requestPermission();
  }, []);

  const origin = console.error;
  console.error = (error) => {
    if (/Loading chunk [\d]+ failed/.test(error.message)) {
     
      window.location.reload();
    } else {
      origin(error);
    }
  };
  return (
    <div className="relative  roboto-regular">
      <ScrollToTop />
      <CartProvider>
        {/* <Header /> */}
        <Toaster />
        <div className="fixed top-0 z-10 w-full">
          {(window.location.pathname !== "/PrivacyPolicy" ||
            window.location.pathname !== "/successorder") && <Navbar />}
        </div>
        <Suspense fallback={<OverlayLoader />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/search" element={<SearchResult />} />
            <Route path="/Skinco" element={<Skinco />} />
            <Route path="/Skinco/*" element={<Skinco />} />
            <Route path="/spring" element={<Spring />} />
            <Route path="/spring/*" element={<Spring />} />
            <Route path="/haircare" element={<Cartdescription />} />
            <Route path="/haircare/*" element={<Cartdescription />} />
            {/* <Route path="/best-sellers" element={<BestSeller/>} /> */}
            <Route path="/hair-care" element={<Haircareallproducts />} />
            <Route path="/skin-care" element={<Skincareallproducts />} />
            <Route path="/best-sellers" element={<Bestsellerlist />} />
            <Route
              path="/best-sellers-combos"
              element={<Skincareallproducts />}
            />
            <Route path="/newlaunches" element={<Skincareallproducts />} />
            {/* <Route path="/cartdescription/:productname/:id" element={<Cartdescription/>} /> */}
            <Route
              path="/products/:shortUrl"
              element={<ProductDiscription />}
            />
            <Route
              path="/training/:id"
              element={<Training />}
            />
            <Route path="/Argatin" element={<Argatin />} />
            <Route path="/Argatin/*" element={<Argatin />} />
            <Route path="/Login" element={<Logins />} />
            <Route path="/SignUp" element={<SignUp />} />
            <Route path="/ResetPassword" element={<ResetPassword />} />
            <Route path="/Menicure" element={<Menicure />} />
            <Route path="/FacialKit" element={<FacialKit />} />
            <Route path="/Profile" element={<Profile />} />
            <Route path="/AddressModal" element={<Shipping />} />
            <Route path="/Faq" element={<Faq />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicyComponent />} />
            <Route path="/ReturnRefund" element={<ReturnRefund />} />
            {/* <Route path="/ordersummary" element={<Ordersummary/>} /> */}
            <Route path="/ordersummary" element={<YourOrders />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/distributer" element={<Distributer />} />
            {/*<Route path="/loginform" element={<LoginForm/>} />*/}
            <Route path="/productdetailform" element={<Productformdetails />} />
            <Route path="/form" element={<Editform />} />
            <Route path="/productupdateform" element={<Productupdateform />} />
            <Route path="/formupdate" element={<Formupdate />} />
            <Route path="/btx-hair-care" element={<Btxhaircare />} />
            <Route path="/argatin-shampoo" element={<Argatinshampoo />} />
            <Route path="/hairconditioner" element={<Hairconditioner />} />
            <Route path="/hairoil" element={<Hairoil />} />
            <Route
              path="/argatin-keratin-treatment"
              element={<Argatinkeratintreatment />}
            />
            <Route path="/product-combo" element={<Listcombo />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/TermsAndCondition" element={<TermsAndCondition />} />
            <Route path="/loreal" element={<Loreal />} />
            <Route path="/loreal/*" element={<Loreal />} />
            <Route path="/lorealallproducts" element={<Lorealallproducts />} />
            <Route path="/myprofile" element={<Profile />} />
            <Route path="/addresses" element={<Address />} />
            <Route path="/unlisting" element={<Unlisting />} />
            <Route path="/successorder" element={<SuccessOrder />} />
            <Route path="/Sitemap" element={<Sitemap />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </Suspense>

        {/* <Footer /> */}
        {/* {(window.location.pathname !== "/PrivacyPolicy" || window.location.pathname !== "/successorder")  && <Footer />} */}
        {(window.location.pathname !== "/PrivacyPolicy" ||
          window.location.pathname !== "/successorder") && <NewFooter />}
      </CartProvider>
    </div>
  );
}

export default App;

// import React, { useState } from "react";
// import "./App.css";
// // import Modal from "./Components/Modal";
// import Modal from "./commonComponent/rawmodal";

// function App() {
//   const [modalOpen, setModalOpen] = useState(false);

//   return (
//     <div className="App">
//       <h1>Hey, click on the button to open the modal.</h1>
//       <button
//         className="openModalBtn"
//         onClick={() => {
//           setModalOpen(true);
//         }}
//       >
//         Open
//       </button>

//       {modalOpen && <Modal setOpenModal={setModalOpen/>} />}
//     </div>
//   );
// }

// export default App;
