import axios from "axios";
import { store } from "../Redux/store/store";
const BASE_URL = "https://prosaloncart.com/";
// const BASE_URL = "http://192.168.2.211:4001/";
export const BASE_URL2 = "https://crm.smartsalon.in/";
// export const BASE_URL2 = 'http://192.168.2.211:4002/';

const authToken = store.getState();
console.log("authToken",authToken)
const token = authToken.authReducer.userData;
// console.log("tokendata",authToken)

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    "X-Custom-Header": "foobar", // Custom headers if needed
    "Content-Type": "application/json", // Sample content type header
    Authorization: `Bearer ${token}`,
  },
});

const setAuthorizationToken = (auth_token) =>{
  if(auth_token){
    instance.defaults.headers.common['Authorization'] = auth_token;
  }
}
const postApiData = (endpoint, apidata, success, failure) => {
  const authToken = store.getState();
  const token = authToken.authReducer.userData
  const instance = axios.create({
    baseURL: BASE_URL,
    timeout: 30000,
    headers: {
      "X-Custom-Header": "foobar", // Custom headers if needed
      "Content-Type": "application/json", // Sample content type header
      Authorization: `Bearer ${token}`,
    },
  });
  instance
    .post(endpoint, apidata)
    .then((res) => {
      console.log('otpresponse',res)
      success(res?.data?.data);
    })
    .catch((error) => {
      console.log("databaase", error);
      // failure(error)
    });
};
const getApiCall = (endpoint, success, failure,isCrm=false) => {
  // console.log("apidata",apidata)
  const authToken = store.getState();
  const token = authToken.authReducer.userData

  const instance = axios.create({
    baseURL: isCrm?BASE_URL2:BASE_URL,
    timeout: 30000,
    headers: {
      "X-Custom-Header": "foobar", // Custom headers if needed
      "Content-Type": "application/json", // Sample content type header
      Authorization: `Bearer ${token}`,
    },
  });
 
  instance
    .get(endpoint)
    .then((res) => {
      console.log("checkresponse", res?.data.data);
      success(res?.data?.data);
    })
    .catch((error) => {
      failure(error)
    });
};


export { postApiData, getApiCall,instance,};
