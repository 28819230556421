import React, { useEffect, useState } from "react";
import { BrowserRouter as Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import "./addressmodal.css";
import { getApiCall, postApiData } from "../../utils/services";
import axios from "axios";
import AddNewAddress from "../addnewAddress";
import { LOCAL_IMAGES } from "../../utils/localimages";
import { useSelector } from "react-redux";
import setAuthorizationToken from "../../utils/commonFunction";
import { FaIndianRupeeSign } from "react-icons/fa6";
import toast from "react-hot-toast";
import { useLocation } from 'react-router-dom';

export default function AddressModal({
  addressmodal,
  setTest,
  setAddressModal,
}) {
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [selectaddress, setSelectaddress] = useState("");
  const [isgif, setgif] = useState(false);
  const [addNewAddressModal, setAddNewAddress] = useState(false);
  const [filledAddress, setFilledAddress] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedAddressObject, setSelectedAddressObject] = useState(null);
  const [summery, setSummery] = useState(false);
  const [cartDetails, setCartDetails] = useState([]);
  const [orderid, setOrderId] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [cash, setCash] = useState(false);
  const [paymentonline, setPayemntOnline] = useState(false);
  const [updatedAmount, setupdatedAmount] = useState(0);
  console.log("totalamountcoming",updatedAmount)

  const [isModalOpen, setModalOpen] = useState(false);
  const cartData = useSelector((store) =>
  store.cartDetailsReducer.cartLength);
  const navigate = useNavigate();
  const location = useLocation();
  const openCartpress=location.state


  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const token = useSelector((store) => store.authReducer.userData);
  const totalAmountpayable = useSelector(
    (store) => store.addProduct.cartpayableAmount
  );
  useEffect(() => {
    if (token) {
      setAuthorizationToken(token);
    }
  }, []);
  useEffect(() => {
    if (cash === true) {
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, [cash]);
  useEffect(() => {
    if (paymentonline === true) {
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, [paymentonline]);

  useEffect(() => {
    if (totalAmountpayable < 500) {
      const updatedamountwithDelivery = totalAmountpayable + 50;
      setupdatedAmount(updatedamountwithDelivery);
    } else {
      setupdatedAmount(totalAmountpayable);
    }
  }, [totalAmountpayable]);

  const handleSelectChange = (event) => {
    console.log("adddresss id -----", event.target.value);
    const selectedAddress = filledAddress?.filter(
      (item) => item._id === event.target.value
    );
    console.log("selected address ", selectedAddress[0]);
    // const addressObj = selectedAddress[0]
    const [address, address2, city, state, pincode] = selectedAddress;
    console.log(address, address2, city, state, pincode);
    setSelectaddress({ address, address2, city, state, pincode });
  };
  console.log("selected addressssss------------", selectaddress.address);
  console.log("handle select change", selectedOption);

  function paymentbycash() {
    setgif(!isgif);
  }

  useEffect(() => {
    const selectedObject = filledAddress.find(
      (address) =>
        address.address2 + address.city + address.state + address.pincode ===
        selectedOption
    );

    // Update the selectedAddressObject state with the selected object
    setSelectedAddressObject(selectedObject);
  }, [selectedOption]);

  console.log("address ki ------------------", selectedAddressObject);

  function truncateProductName(name) {
    const words = name.split(" ");

    if (words?.length > 7) {
      const truncatedWords = words.slice(0, 10);
      return `${truncatedWords.join(" ")} ...`;
    }

    return name;
  }
  const loadScript = (src, callback) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    script.onload = callback;
    document.head.appendChild(script);
  };

  // Load Razorpay SDK
  loadScript("https://checkout.razorpay.com/v1/checkout.js", () => {
    // Now you can create a Razorpay instance
  });
  <script src="https://checkout.razorpay.com/v1/checkout.js"></script>;
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  console.log("selected option", selectedOption);
  const addnewAddress = (e) => {
    setAddNewAddress(!addNewAddressModal);
    e.preventDefault();
  };
  const captureOrderDetails = (razorpayid, orderId,totalAmount) => {
    const data = {
      orderId: orderId,
      // orderId: orderData._doc._id,
      orderAmount: totalAmount,
      paymentMethod: selectedOption == "online" ? 2 : 1,
      // selectedOption,
      razorPayId: razorpayid,
    };
    postApiData("user/captureProductOrder", data, (response) => {
      if (response) {
        setPayemntOnline(true);
        toast.success("order has been placed")
        navigate("/")
      }
    });
  };

  useEffect(() => {
    const data = {};
    axios
      .post("https://prosaloncart.com/user/getCartDetails", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(
          "Responsedatadsfhsahhfsdsdg:",
          response.data.data[0].products
        );
        setCartDetails(response.data.data[0]?.products);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [openCartpress]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "whitesmoke",
      position: "absolute",
      zIndex: 899,
      borderRadius: "20px",
      paddingRight: "0px",
    },
  };

  const onClickPayment = () => {
    const data = {
      phoneNumber: phoneNumber,
      name: name,
      paymentMethod: selectedOption === "online" ? 2 : 1,
      addresses: {
        address: selectaddress.address.address,
        address2: selectaddress.address.address2,
        city: selectaddress.address.city,
        state: selectaddress.address.state,
        pincode: selectaddress.address.pincode,
        name: name,
        phoneNumber: phoneNumber,
      },
      orderAmount: totalAmountpayable,
    };

    postApiData(
      "user/createProductOrder",
      data,
      (response) => {
        setOrderId(response?._doc?._id);
        setTotalAmount(response?._doc?.totalPayableAmount);

        if (selectedOption === "cash" && name && phoneNumber) {
          // setCash(true);
          toast.success("You Order has been Successfully Placed!!")
          navigate("/successorder")
        }

        if (selectedOption === "online" && name && phoneNumber) {
          const options = {
            // key: "rzp_test_hdlZcgaBXfBRBr",
            // key: "rzp_live_ZmjmD6vtJZ9giq",
            key: "rzp_live_gPOHKfuDqbcZFw",
            amount: response.amount, // Amount in paise
            currency: "INR",
            name: "SalonKart",
            description: "Test Transaction",
            order_id: response.id,
            handler: async (responses) => {
              if (responses) {
                console.log("razorpay ka response ------", responses);
                // captureOrderDetails(response?.razorpay_payment_id, response?.razorpay_order_id);
                captureOrderDetails(responses?.razorpay_payment_id, response?._doc?._id,response?._doc?.totalPayableAmount);
                toast.success("Your order has been placed")
                navigate("/")
              }
            },
            prefill: {
              name: name,
              email: "customer@example.com",
              contact: phoneNumber,
            },
            notes: {
              address: "Note Address",
            },
            theme: {
              color: "#61dafb",
            },
          };

          const razorpay = new window.Razorpay(options);
          razorpay.open();

          razorpay.on("payment.failed", function (response) {
            alert(response.error.reason);
          });
        }
      },
      (error) => {
        alert("order error: " + error.message); // Assuming error.message contains the actual error message
      }
    );
  };

  useEffect(() => {
    getApiCall("user/getUserAddress", (resp) => {
      console.log("user/getUserAddress", resp);
      setFilledAddress(resp.addresses);
    });
  }, [addressmodal, selectedOption, addNewAddressModal]);
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };
  const onclicksummery = () => {
    setSummery(!summery);
  };
  const backpress = () => {
    setAddressModal(false);
  };

  console.log("filled address ------", filledAddress);
  return (
    <div style={{ height: "100%", width: "100%" }} className="mt-24">
      <div className="salonkartcontianerabc">
        <span className="salonkart">{"SalonKart"}</span>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "6px",
          }}
        >
          <FaIndianRupeeSign size={17} />

          <span className="totalprice">{totalAmountpayable}</span>
          {totalAmountpayable < 500 && (
            <span className="totalprice">
              {" "}
              {totalAmount}+₹ 50(Delivery Charge) ₹ {updatedAmount}
            </span>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span className="freeshipping">
          {"Free shipping on orders above 449"}
        </span>
      </div>

      {cash && (
        <div>
          <Modal
            isOpen={cash}
            onRequestClose={closeModal}
            contentLabel="Order Successful Popup"
            className="order-successful-modal"
            overlayClassName="order-successful-overlay"
            style={{
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                background: "whitesmoke",
                position: "absolute",
                zIndex: 899,
                borderRadius: "20px",
                paddingRight: "0px",
              },
            }}
          >
            <>
              <div className="order-successful-container">
                <img  loading="lazy" className="gif" src={LOCAL_IMAGES.popupgif} alt="img" />
              </div>
              <div className="return-to-home">
                <Link to="/" className="style-add">
                  Return To Home
                </Link>
              </div>
            </>
          </Modal>
        </div>
      )}
      <div
        style={{
          // backgroundColor: "",
          // display: "flex",
          // alignItems: "center",
          // justifyContent: "space-between",
          // marginTop: "6px",
          // marginBottom: "6px",
          display: "flex",
          alignItems: "center",
          marginTop: "6px",
          marginBottom: "6px",
          justifyContent: "center",
        }}
      >
        <img
        loading="lazy"
          src={LOCAL_IMAGES.orderProcess}
          style={{ height: "50px", width: "50px" }}
        />
        <p className="orderSummary">Order Summary</p>
        <div style={{ background: "" }}>
          <img
          loading="lazy"
            onClick={onclicksummery}
            src={LOCAL_IMAGES.downArrow}
            style={{ height: "30px", width: "30px" }}
          />
          {/* <p>kjdjkd</p> */}
        </div>
      </div>
      {summery &&
        cartData.map((item, index) => (
          // return(
          <>
            <div className="cartorder-container">
              <div className="cartimg-link">
                <img  loading="lazy" src={item?.images[0]} />
              </div>

              <div className="name-container">
                <span className="cart-name">
                  {truncateProductName(item.name)}
                </span>
                <div className="name-cart-container">
                  <p className="cart-price">{item.price * item.quantity}</p>
                  <p className="quantity">Qt:{item.quantity}</p>
                </div>
              </div>
            </div>
          </>

          // )
        ))}

      <form className="form">
        <div className="formcontainer1">
          <div className="formcontainer">
            <label htmlFor="labeled-input">Name:</label>
            <input
              className="textinputstyle"
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="formcontainer">
            <label htmlFor="labeled-input">Phone Number:</label>

            <input
              className="textinputstyle"
              type="text"
              placeholder="Enter your phone number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
        </div>

        <div className="formcontainer">
          <label htmlFor="selectField">Select an Address:</label>
          <select
            className="textinputstyleaddress"
            onChange={handleSelectChange}
          >
            {/* Map over the address objects array to create option elements */}
            <option value={selectaddress}>SELECT YOUR ADDRESS</option>
            {filledAddress.map((option) => (
              <option
                key={option._id}
                // value={option.address2 +" "+ option.city +" "+ option.state +" "+ option.pincode}
                // value={`${option.address},${option.address2},${option.city},${option.state},${option.pincode}`}
                value={option._id}
              >
                {option?.address}, {option.address2}, {option.city},{" "}
                {option.state},{option.pincode}
              </option>
            ))}
          </select>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            onClick={addnewAddress}
            className="buttonstyle"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
              borderWidth: "1px",
              borderRadius: "20px",
            }}
          >
            Add New Address
          </button>
        </div>

        {
          <AddNewAddress
            addNewAddress={addNewAddressModal}
            setAddNewAddress={setAddNewAddress}
          />
        }
      </form>
      <div className="my-4 flex justify-center items-center ">
        <h1 className="bg-black text-white font-bold text-lg px-7 py-2 rounded-xl">
          Payment Methods
        </h1>
      </div>

      <div
        style={{
          backgroundColor: "",
          marginTop: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <label style={{}} className="text-lg px-2">
          <input
            type="radio"
            value="cash"
            checked={selectedOption === "cash"}
            style={{ marginRight: "3px" }}
            onChange={handleOptionChange}
          />
          cash
        </label>
        <label style={{ marginLeft: "20px" }} className="text-lg px-2">
          <input
            type="radio"
            value="online"
            checked={selectedOption === "online"}
            style={{ marginRight: "3px" }}
            onChange={handleOptionChange}
          />
          online
        </label>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        {
          // selectedOption === "online" ? (
          <button onClick={onClickPayment} className="buttonstyle">
            Complete Purchase
          </button>
        }
      </div>
    </div>
  );
}
